import React, {useEffect, useContext, useCallback} from "react";
import Context from "./Context";
import styles from "./App.module.scss";
import Link from "./Components/Link";
import Debit from "./Components/Debit";
require('dotenv').config();

const URL_API = process.env.REACT_APP_API_HOST

const App = () => {
    const {
        linkToken,
        isCardholder, isDebit, errorCode, token, dispatch
    } = useContext(Context);

    const queryParams = new URLSearchParams(window.location.search)
    const myToken = queryParams.get("token") || '';
    const isdebit = queryParams.get("isdebit") || '';
    const lang = queryParams.get("lang") || 'en';
    const callback_uri = queryParams.get("callback_uri");
    //console.log("isdebit", isdebit);
    const checkParams = React.useCallback(async () => {
        // //console.log("carholder",cardHolderId);
        // //console.log("callback_uri",callback_uri);
        // //console.log("isdebit",isdebit);

        if (!myToken || !callback_uri) {
            dispatch({
                type: "SET_STATE",
                state: {
                    errorCode: "Oops something happens! 10100"
                }
            });
            return false;
        } else {
            if (isdebit === "true") {
                dispatch({
                    type: "SET_STATE",
                    state: {
                        isDebit: true
                    }
                });

            }
            return true;
        }

    }, [callback_uri, myToken, isdebit, dispatch]);

    const getInfo = React.useCallback(async () => {

        const response = await fetch( URL_API + "/api/check",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + myToken,
                },
            });

        const data = await response.json();

        if (!response.ok) {
            //console.log("check not ok!")
            dispatch({
                type: "SET_STATE",
                state: {
                    // backend: false,
                    errorCode: "Oops something happens! " + data.error
                }
            });
            return;
        }

        //console.log(data);

        dispatch({
            type: "SET_STATE",
            state: {
                isCardholder: true,
                token: myToken
            },
        });
        return;
    }, [myToken, dispatch]);

    const generateToken = React.useCallback(
        async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const lang = queryParams.get("lang") || 'es';
            const path = URL_API + "/api/create_link_token";
            const response = await fetch(path, {
                method: "POST",
                headers: {
                    language: lang
                }
            });

            if (!response.ok) {
                dispatch({
                    type: "SET_STATE",
                    state: {
                        linkToken: null
                    }
                });
                return;
            }

            const data = await response.json();

            if (data) {
                if (data.error != null) {
                    dispatch({
                        type: "SET_STATE",
                        state: {
                            linkToken: null,
                            linkTokenError: data.error,
                        },
                    });
                    return;
                }
                dispatch({
                    type: "SET_STATE",
                    state: {
                        linkToken: data.link_token
                    }
                });
            }
            localStorage.setItem("link_token", data.link_token); //to use later for Oauth
        },
        [dispatch]
    );

    const close = (status: string) => {
        //console.log('calling close')
        if (callback_uri) {
            let buff = new Buffer(callback_uri, 'base64');
            let uri = buff.toString('ascii') + '?status=' + status;
            // //console.log('Decoded callback_uri: ', uri);
            window.location.replace(uri);
            return;
        }

        window.parent.postMessage({
            appname: 'XPAY-LADONWARE-API',
            status: status
        }, "*");
    }

    useEffect(() => {
        const init = async () => {
            if (await checkParams()) {
                //console.log("Params ok!")
                await getInfo(); // used to determine which path to take when generating token
                // do not generate a new token for OAuth redirect; instead
                // setLinkToken from localStorage

                if (window.location.href.includes("?oauth_state_id=")) {
                    dispatch({
                        type: "SET_STATE",
                        state: {
                            linkToken: localStorage.getItem("link_token")
                        },
                    });
                    return;
                }
                await generateToken();
            } else {
                //console.log("Params not ok!")
                // close('FAILED');
            }

        };

        init();

    }, [checkParams, generateToken, getInfo, dispatch]);


    return (
        <div className={styles.App}>
            <div className={styles.container}>

                {(!isDebit) ? (
                        (linkToken != null && isCardholder) ? (<Link/>) : (<h5>{errorCode}</h5>)
                    ) :
                    (isCardholder) ? (<Debit/>) : (<h5>{errorCode}</h5>)
                }
            </div>
        </div>
    );
};

export default App;
